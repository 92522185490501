define("ember-file-upload/components/file-dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QmMOWHdJ",
    "block": "[[[18,1,[[28,[37,1],null,[[\"supported\",\"active\",\"valid\"],[[31,2],[31,3],[31,4]]]],[31,5]]]],[\"&default\"],false,[\"yield\",\"hash\",\"supported\",\"active\",\"valid\",\"queue\"]]",
    "moduleName": "ember-file-upload/components/file-dropzone/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});