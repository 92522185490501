define("ember-models-table/utils/emt/macros", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shownColumnsBody = void 0;

  const shownColumnsBody = (processedColumns, colspanKey) => {
    let skipCount = 0;
    return processedColumns.filter((c, index, columns) => {
      const colspan = (0, _object.get)(c, colspanKey) || 1;
      const isVisible = c.isVisible;
      const nextHiddenCells = columns.slice(index + 1, index + colspan).filter(c => c.isHidden);

      if (nextHiddenCells.length === colspan - 1 && !isVisible && colspan !== 1) {
        return false;
      }

      if (skipCount) {
        skipCount--;
        return false;
      }

      if (colspan === 1) {
        return isVisible;
      }

      if (colspan > 1) {
        skipCount = colspan - 1;
      }

      return true;
    });
  };

  _exports.shownColumnsBody = shownColumnsBody;
});