define("ember-modal-dialog/components/in-place-dialog", ["exports", "@ember-decorators/component", "@ember/component", "ember-modal-dialog/templates/components/in-place-dialog"], function (_exports, _component, _component2, _inPlaceDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let InPlaceDialog = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_inPlaceDialog.default), _dec(_class = _dec2(_class = class InPlaceDialog extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "containerClass", null);
    }

    // passed in
    init() {
      super.init(...arguments);
      this.containerClassNames = ['ember-modal-dialog', 'ember-modal-dialog-in-place', 'emd-in-place']; // set this in a subclass definition
    }

    get containerClassNamesString() {
      var _this$containerClassN, _this$containerClassN2;

      return ((_this$containerClassN = this.containerClassNames) === null || _this$containerClassN === void 0 ? void 0 : _this$containerClassN.join) && ((_this$containerClassN2 = this.containerClassNames) === null || _this$containerClassN2 === void 0 ? void 0 : _this$containerClassN2.join(' ')) || this.containerClassNames || '';
    }

  }) || _class) || _class);
  _exports.default = InPlaceDialog;
});