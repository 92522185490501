define("ember-models-table/components/models-table/themes/default/row-grouping", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "@glimmer/component", "ember-models-table/utils/emt/array"], function (_exports, _component, _templateFactory, _util, _component2, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class="{{@themeInstance.groupingRow}}">
    <td
      class={{@themeInstance.groupingCell}}
      colspan={{this.cellColspan}}>
      {{#let
        (
          hash
            RowGroupToggle=(
              component (ensure-safe-component this.rowGroupToggleComponent)
              groupedValue=@groupedValue
              groupedLength=@groupedLength
              currentGroupingPropertyName=@currentGroupingPropertyName
              displayGroupedValueAs=@displayGroupedValueAs
              visibleProcessedColumns=@visibleProcessedColumns
              groupedItems=@groupedItems
              selectedItems=@selectedItems
              expandedItems=@expandedItems
              visibleGroupedItems=@visibleGroupedItems
              selectedGroupedItems=this.selectedGroupedItems
              expandedGroupedItems=this.expandedGroupedItems
              groupIsCollapsed=@groupIsCollapsed
              themeInstance=@themeInstance
              toggleGroupedRowsSelection=@toggleGroupedRowsSelection
              toggleGroupedRowsExpands=@toggleGroupedRowsExpands
              toggleGroupedRows=@toggleGroupedRows
            )
        )
      as |RowGrouping|
      }}
        {{#if (has-block)}}
          {{yield RowGrouping}}
        {{else}}
          <RowGrouping.RowGroupToggle/>
        {{/if}}
      {{/let}}
    </td>
  </tr>
  */
  {
    "id": "oMHHiWag",
    "block": "[[[10,\"tr\"],[15,0,[29,[[30,1,[\"groupingRow\"]]]]],[12],[1,\"\\n  \"],[10,\"td\"],[15,0,[30,1,[\"groupingCell\"]]],[15,\"colspan\",[30,0,[\"cellColspan\"]]],[12],[1,\"\\n\"],[44,[[28,[37,1],null,[[\"RowGroupToggle\"],[[50,[28,[37,3],[[30,0,[\"rowGroupToggleComponent\"]]],null],0,null,[[\"groupedValue\",\"groupedLength\",\"currentGroupingPropertyName\",\"displayGroupedValueAs\",\"visibleProcessedColumns\",\"groupedItems\",\"selectedItems\",\"expandedItems\",\"visibleGroupedItems\",\"selectedGroupedItems\",\"expandedGroupedItems\",\"groupIsCollapsed\",\"themeInstance\",\"toggleGroupedRowsSelection\",\"toggleGroupedRowsExpands\",\"toggleGroupedRows\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,0,[\"selectedGroupedItems\"]],[30,0,[\"expandedGroupedItems\"]],[30,11],[30,1],[30,12],[30,13],[30,14]]]]]]]],[[[41,[48,[30,16]],[[[1,\"        \"],[18,16,[[30,15]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,15,[\"RowGroupToggle\"]],null,null,null],[1,\"\\n\"]],[]]]],[15]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@themeInstance\",\"@groupedValue\",\"@groupedLength\",\"@currentGroupingPropertyName\",\"@displayGroupedValueAs\",\"@visibleProcessedColumns\",\"@groupedItems\",\"@selectedItems\",\"@expandedItems\",\"@visibleGroupedItems\",\"@groupIsCollapsed\",\"@toggleGroupedRowsSelection\",\"@toggleGroupedRowsExpands\",\"@toggleGroupedRows\",\"RowGrouping\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"ensure-safe-component\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/row-grouping.hbs",
    "isStrictMode": false
  });

  /**
   * Component-row with grouped property value. Used to toggle rows group with needed property value equal to the grouping value.
   *
   * You must not override this component and should use [[Core.ModelsTable.groupingRowComponent | ModelsTable.groupingRowComponent]].
   *
   * By default [[DefaultTheme.RowGroupToggle]] is used
   */
  class RowGrouping extends _component2.default {
    get cellColspan() {
      return this.args.visibleProcessedColumns.length + (this.args.displayGroupedValueAs === 'row' ? 0 : 1) + Number(this.args.additionalColspan || 0);
    }

    get selectedGroupedItems() {
      return (0, _array.intersection)(this.args.selectedItems, this.args.groupedItems);
    }

    get expandedGroupedItems() {
      return (0, _array.intersection)(this.args.expandedItems, this.args.groupedItems);
    }

    get rowGroupToggleComponent() {
      return this.args.groupingRowComponent ? (0, _util.ensureSafeComponent)(this.args.groupingRowComponent, this) : (0, _util.ensureSafeComponent)(this.args.themeInstance.rowGroupToggleComponent, this);
    }

  }

  _exports.default = RowGrouping;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowGrouping);
});