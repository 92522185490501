define("ember-models-table/services/emt-themes/plain-html", ["exports", "@embroider/util", "ember-models-table/services/emt-themes/default", "ember-models-table/components/models-table/themes/plain-html/columns-dropdown", "ember-models-table/components/models-table/themes/plain-html/global-filter", "ember-models-table/components/models-table/themes/plain-html/row-filtering-cell", "ember-models-table/components/models-table/themes/plain-html/summary"], function (_exports, _util, _default, _columnsDropdown, _globalFilter, _rowFilteringCell, _summary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PlainHtmlTheme extends _default.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "headerWrapper", 'models-table-header');

      _defineProperty(this, "tfooterInternalWrapper", 'models-table-footer');

      _defineProperty(this, "paginationInternalWrapper", 'pagination-wrapper');

      _defineProperty(this, "currentPageSizeSelectWrapper", 'page-size-select-wrapper');

      _defineProperty(this, "columnsDropdownWrapper", 'columns-dropdown');

      _defineProperty(this, "clearFilterIcon", 'fa fa-times form-control-feedback');

      _defineProperty(this, "clearAllFiltersIcon", 'fa fa-times');

      _defineProperty(this, "sortAscIcon", 'fa fa-sort-asc');

      _defineProperty(this, "sortDescIcon", 'fa fa-sort-desc');

      _defineProperty(this, "columnVisibleIcon", 'fa fa-check-square-o');

      _defineProperty(this, "columnHiddenIcon", 'fa fa-square-o');

      _defineProperty(this, "navFirstIcon", 'fa fa-angle-double-left');

      _defineProperty(this, "navPrevIcon", 'fa fa-angle-left');

      _defineProperty(this, "navNextIcon", 'fa fa-angle-right');

      _defineProperty(this, "navLastIcon", 'fa fa-angle-double-right');

      _defineProperty(this, "caretIcon", 'caret');

      _defineProperty(this, "expandRowIcon", 'fa fa-plus');

      _defineProperty(this, "expandAllRowsIcon", 'fa fa-plus');

      _defineProperty(this, "collapseRowIcon", 'fa fa-minus');

      _defineProperty(this, "collapseAllRowsIcon", 'fa fa-minus');

      _defineProperty(this, "selectAllRowsIcon", 'fa fa-check-square-o');

      _defineProperty(this, "selectSomeRowsIcon", 'fa fa-minus-square-o');

      _defineProperty(this, "deselectAllRowsIcon", 'fa fa-square-o');

      _defineProperty(this, "selectRowIcon", 'fa fa-check-square-o');

      _defineProperty(this, "deselectRowIcon", 'fa fa-square-o');
    }

    /**
     * @default 'models-table/themes/plain-html/columns-dropdown'
     */
    get columnsDropdownComponent() {
      return (0, _util.ensureSafeComponent)(_columnsDropdown.default, this);
    }
    /**
     * @default 'models-table/themes/plain-html/global-filter'
     */


    get globalFilterComponent() {
      return (0, _util.ensureSafeComponent)(_globalFilter.default, this);
    }
    /**
     * @default 'models-table/themes/plain-html/row-filtering-cell'
     */


    get rowFilteringCellComponent() {
      return (0, _util.ensureSafeComponent)(_rowFilteringCell.default, this);
    }
    /**
     * @default 'models-table/themes/plain-html/summary'
     */


    get summaryComponent() {
      return (0, _util.ensureSafeComponent)(_summary.default, this);
    }

  }

  _exports.default = PlainHtmlTheme;
});