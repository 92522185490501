define("ember-models-table/components/models-table/themes/ember-paper/pagination-numeric", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/pagination-numeric", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _paginationNumeric, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.paginationWrapper}} {{@themeInstance.paginationWrapperNumeric}}">
    {{#let
      (hash
        PageNumberSelect=(
          component (ensure-safe-component @themeInstance.selectComponent)
          id=this.inputId
          label=@themeInstance.currentPageNumberMsg
          options=@currentPageNumberOptions
          value=@currentPageNumber
          type="number"
          themeInstance=@themeInstance
          changeValue=this.goToPage)
        visiblePageNumbers=this.visiblePageNumbers)
    as |Pagination|
    }}
      {{#if (has-block)}}
        {{yield Pagination}}
      {{else}}
        <div class={{@themeInstance.paginationInternalWrapper}}>
          {{#if @showCurrentPageNumberSelect}}
            <Pagination.PageNumberSelect/>&nbsp;
          {{/if}}
          <div class={{@themeInstance.paginationBlock}}>
            {{#each this.visiblePageNumbers as |page|}}
              {{#if page.isLink}}
                <this.PaperButton
                  class={{@themeInstance.buttonDefault}}
                  @onClick={{fn this.goToPage page.label}}>
                  {{page.label}}
                </this.PaperButton>
              {{else}}
                <this.PaperButton
                  class={{@themeInstance.buttonDefault}}
                  @disabled={{true}}>
                  {{page.label}}
                </this.PaperButton>
              {{/if}}
            {{/each}}
          </div>
        </div>
      {{/if}}
    {{/let}}
  </div>
  */
  {
    "id": "JsJpuL0/",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"paginationWrapper\"]],\" \",[30,1,[\"paginationWrapperNumeric\"]]]]],[12],[1,\"\\n\"],[44,[[28,[37,1],null,[[\"PageNumberSelect\",\"visiblePageNumbers\"],[[50,[28,[37,3],[[30,1,[\"selectComponent\"]]],null],0,null,[[\"id\",\"label\",\"options\",\"value\",\"type\",\"themeInstance\",\"changeValue\"],[[30,0,[\"inputId\"]],[30,1,[\"currentPageNumberMsg\"]],[30,2],[30,3],\"number\",[30,1],[30,0,[\"goToPage\"]]]]],[30,0,[\"visiblePageNumbers\"]]]]]],[[[41,[48,[30,7]],[[[1,\"      \"],[18,7,[[30,4]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[30,1,[\"paginationInternalWrapper\"]]],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"          \"],[8,[30,4,[\"PageNumberSelect\"]],null,null,null],[1,\" \\n\"]],[]],null],[1,\"        \"],[10,0],[15,0,[30,1,[\"paginationBlock\"]]],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"visiblePageNumbers\"]]],null]],null],null,[[[41,[30,6,[\"isLink\"]],[[[1,\"              \"],[8,[30,0,[\"PaperButton\"]],[[16,0,[30,1,[\"buttonDefault\"]]]],[[\"@onClick\"],[[28,[37,9],[[30,0,[\"goToPage\"]],[30,6,[\"label\"]]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,6,[\"label\"]]],[1,\"\\n              \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"              \"],[8,[30,0,[\"PaperButton\"]],[[16,0,[30,1,[\"buttonDefault\"]]]],[[\"@disabled\"],[true]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,6,[\"label\"]]],[1,\"\\n              \"]],[]]]]],[1,\"\\n\"]],[]]]],[6]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[4]]],[13]],[\"@themeInstance\",\"@currentPageNumberOptions\",\"@currentPageNumber\",\"Pagination\",\"@showCurrentPageNumberSelect\",\"page\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"ensure-safe-component\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"fn\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/pagination-numeric.hbs",
    "isStrictMode": false
  });

  class PaginationNumeric extends _paginationNumeric.default {
    get PaperButton() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperButton)(), this);
    }

  }

  _exports.default = PaginationNumeric;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PaginationNumeric);
});