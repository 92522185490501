define("ember-models-table/components/models-table/themes/ember-paper/select", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "@ember/object", "@glimmer/component", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _object, _component2, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <this.PaperSelect
    id={{this.id}}
    @label={{@label}}
    @options={{@options}}
    @selected={{@value}}
    @disabled={{this.disabled}}
    @placeholder={{@placeholder}}
    @onChange={{this.updateValue}}
    class="{{@themeInstance.input}} {{@themeInstance.select}} {{@cssPropertyName}}"
  as |opt|>
    {{#if (includes "value" (keys opt))}}
      <span aria-valuetext={{opt.value}}>{{opt.label}}</span>
    {{else}}
      {{opt}}
    {{/if}}
  </this.PaperSelect>
  {{yield}}
  */
  {
    "id": "NIhBlYZ1",
    "block": "[[[8,[30,0,[\"PaperSelect\"]],[[16,1,[30,0,[\"id\"]]],[16,0,[29,[[30,1,[\"input\"]],\" \",[30,1,[\"select\"]],\" \",[30,2]]]]],[[\"@label\",\"@options\",\"@selected\",\"@disabled\",\"@placeholder\",\"@onChange\"],[[30,3],[30,4],[30,5],[30,0,[\"disabled\"]],[30,6],[30,0,[\"updateValue\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,1],[\"value\",[28,[37,2],[[30,7]],null]],null],[[[1,\"    \"],[10,1],[15,\"aria-valuetext\",[30,7,[\"value\"]]],[12],[1,[30,7,[\"label\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,7]],[1,\"\\n\"]],[]]]],[7]]]]],[1,\"\\n\"],[18,8,null]],[\"@themeInstance\",\"@cssPropertyName\",\"@label\",\"@options\",\"@value\",\"@placeholder\",\"opt\",\"&default\"],false,[\"if\",\"includes\",\"keys\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/select.hbs",
    "isStrictMode": false
  });

  let Select = (_class = class Select extends _component2.default {
    get PaperSelect() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperSelect)(), this);
    }
    /**
     * @default true
     */


    get disabled() {
      return !this.args.options || !this.args.options.length;
    }
    /**
     * @default false
     */


    get clearable() {
      var _this$wide;

      return (_this$wide = this.wide) !== null && _this$wide !== void 0 ? _this$wide : false;
    }
    /**
     * @default false
     */


    get wide() {
      var _this$args$wide;

      return (_this$args$wide = this.args.wide) !== null && _this$args$wide !== void 0 ? _this$args$wide : false;
    }
    /**
     * @event updateValue
     */


    updateValue(val) {
      let v = typeof val === 'object' ? val.value : val;

      if (this.args.type === 'number') {
        v = +v;
      }

      this.args.changeValue(v);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype)), _class);
  _exports.default = Select;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Select);
});