define("ember-models-table/components/models-table/themes/default/cell-content-display", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{get @record @column.propertyName}}
  
  */
  {
    "id": "CnNtZExP",
    "block": "[[[1,[28,[35,0],[[30,1],[30,2,[\"propertyName\"]]],null]],[1,\"\\n\"]],[\"@record\",\"@column\"],false,[\"get\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/cell-content-display.hbs",
    "isStrictMode": false
  });

  /**
   * Default cell-component. Used to print property-value (`row` - source, `column.propertyName` - key).
   *
   * Properties and event-handlers from [[DefaultTheme.Cell]] are bound here
   */
  class CellContentDisplay extends _component2.default {}

  _exports.default = CellContentDisplay;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CellContentDisplay);
});