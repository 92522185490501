define("ember-models-table/components/models-table/themes/default/cell-content-edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label>
    <Input
      @type="text"
      class={{@themeInstance.input}}
      @value={{get @record @column.propertyName}}/>
  </label>
  {{yield}}
  */
  {
    "id": "iSdtRAYA",
    "block": "[[[10,\"label\"],[12],[1,\"\\n  \"],[8,[39,0],[[16,0,[30,1,[\"input\"]]]],[[\"@type\",\"@value\"],[\"text\",[28,[37,1],[[30,2],[30,3,[\"propertyName\"]]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[18,4,null]],[\"@themeInstance\",\"@record\",\"@column\",\"&default\"],false,[\"input\",\"get\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/cell-content-edit.hbs",
    "isStrictMode": false
  });

  /**
   * Component for cells in the edit-mode.
   *
   * Properties and event-handlers from [models-table/cell](Components.ModelsTableCell.html) are bound here
   */
  class CellContentEdit extends _component2.default {}

  _exports.default = CellContentEdit;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CellContentEdit);
});