define("@jftechnology/ember-keycloak-auth/templates/components/keycloak-session-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OVpqdQfU",
    "block": "[[[41,[31,1,[\"ready\"]],[[[41,[31,1,[\"authenticated\"]],[[[1,\"    \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"logout\"],null],[12],[10,\"i\"],[14,0,\"fa fa-sign-out\"],[12],[13],[1,\" Sign out\"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"login\"],null],[12],[10,\"i\"],[14,0,\"fa fa-sign-in\"],[12],[13],[1,\" Sign in\"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  No session\\n\"]],[]]]],[],false,[\"if\",\"keycloakSession\",\"action\"]]",
    "moduleName": "@jftechnology/ember-keycloak-auth/templates/components/keycloak-session-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});