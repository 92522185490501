define("ember-models-table/components/models-table/themes/default/row-group-toggle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (has-block)}}
    {{yield}}
  {{else}}
    <button type="button" class="{{@themeInstance.buttonDefault}}" {{on "click" this.doToggleGroupedRows}}>{{@groupedValue}}</button>
  {{/if}}
  */
  {
    "id": "28/1wPfB",
    "block": "[[[41,[48,[30,3]],[[[1,\"  \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"button\"],[16,0,[29,[[30,1,[\"buttonDefault\"]]]]],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"doToggleGroupedRows\"]]],null],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]]]],[\"@themeInstance\",\"@groupedValue\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"on\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/row-group-toggle.hbs",
    "isStrictMode": false
  });

  /**
   * Component is used to toggle rows group visibility
   *
   * Don't override this component. Use [[DefaultTheme.RowArgs.groupingRowComponent | RowArgs.groupingRowComponent]] (it may extend this one)
   */
  let RowGroupToggle = (_class = class RowGroupToggle extends _component2.default {
    /**
     * @event toggleGroupedRows
     */
    doToggleGroupedRows(e) {
      var _e$stopPropagation;

      this.args.toggleGroupedRows(this.args.groupedValue);
      e === null || e === void 0 ? void 0 : (_e$stopPropagation = e.stopPropagation) === null || _e$stopPropagation === void 0 ? void 0 : _e$stopPropagation.call(e);
      return false;
    }
    /**
     * @event toggleGroupedRowsSelection
     */


    doToggleGroupedRowsSelection(e) {
      var _e$stopPropagation2;

      this.args.toggleGroupedRowsSelection(this.args.groupedValue);
      e === null || e === void 0 ? void 0 : (_e$stopPropagation2 = e.stopPropagation) === null || _e$stopPropagation2 === void 0 ? void 0 : _e$stopPropagation2.call(e);
      return false;
    }
    /**
     * @event toggleGroupedRowsExpands
     */


    doToggleGroupedRowsExpands(e) {
      var _e$stopPropagation3;

      this.args.toggleGroupedRowsExpands(this.args.groupedValue);
      e === null || e === void 0 ? void 0 : (_e$stopPropagation3 = e.stopPropagation) === null || _e$stopPropagation3 === void 0 ? void 0 : _e$stopPropagation3.call(e);
      return false;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "doToggleGroupedRows", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doToggleGroupedRows"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doToggleGroupedRowsSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doToggleGroupedRowsSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doToggleGroupedRowsExpands", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doToggleGroupedRowsExpands"), _class.prototype)), _class);
  _exports.default = RowGroupToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowGroupToggle);
});