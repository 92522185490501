define("ember-models-table/components/models-table/themes/default/columns-hidden", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
    <td colspan={{this.columnsCount}} class={{@themeInstance.noDataCell}}>
      {{#if (has-block)}}
        {{yield
          (hash
            columnsCount=this.columnsCount
            themeInstance=@themeInstance
          )
        }}
      {{else}}
        {{html-safe @themeInstance.allColumnsAreHiddenMsg}}
      {{/if}}
    </td>
  </tr>
  */
  {
    "id": "FqXMInyV",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"td\"],[15,\"colspan\",[30,0,[\"columnsCount\"]]],[15,0,[30,1,[\"noDataCell\"]]],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[28,[37,3],null,[[\"columnsCount\",\"themeInstance\"],[[30,0,[\"columnsCount\"]],[30,1]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],[[30,1,[\"allColumnsAreHiddenMsg\"]]],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@themeInstance\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"html-safe\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/columns-hidden.hbs",
    "isStrictMode": false
  });

  /**
   * Table row used within [[DefaultTheme.TableBody]].
   *
   * Component used when all columns are hidden and no data items are shown. Usually it contains a simple message.
   *
   * Usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if Body.allColumnsAreHidden}}
   *         <Body.ColumnsHidden as |CH|>
   *           Any message here
   *         </Body.ColumnsHidden>
   *       {{else}}
   *         {{! ... }}
   *       {{/if}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   */
  class ColumnsHidden extends _component2.default {}

  _exports.default = ColumnsHidden;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ColumnsHidden);
});