define("ember-models-table/services/emt-themes/ember-paper", ["exports", "@embroider/util", "ember-models-table/services/emt-themes/default", "ember-models-table/components/models-table/themes/ember-paper/cell-content-edit", "ember-models-table/components/models-table/themes/ember-paper/cell-edit-toggle", "ember-models-table/components/models-table/themes/ember-paper/columns-dropdown", "ember-models-table/components/models-table/themes/ember-paper/data-group-by-select", "ember-models-table/components/models-table/themes/ember-paper/expand-all-toggle", "ember-models-table/components/models-table/themes/ember-paper/expand-toggle", "ember-models-table/components/models-table/themes/ember-paper/global-filter", "ember-models-table/components/models-table/themes/ember-paper/page-size-select", "ember-models-table/components/models-table/themes/ember-paper/pagination-numeric", "ember-models-table/components/models-table/themes/ember-paper/pagination-simple", "ember-models-table/components/models-table/themes/ember-paper/row-filtering-cell", "ember-models-table/components/models-table/themes/ember-paper/row-select-all-checkbox", "ember-models-table/components/models-table/themes/ember-paper/row-select-checkbox", "ember-models-table/components/models-table/themes/ember-paper/select", "ember-models-table/components/models-table/themes/ember-paper/summary"], function (_exports, _util, _default, _cellContentEdit, _cellEditToggle, _columnsDropdown, _dataGroupBySelect, _expandAllToggle, _expandToggle, _globalFilter, _pageSizeSelect, _paginationNumeric, _paginationSimple, _rowFilteringCell, _rowSelectAllCheckbox, _rowSelectCheckbox, _select, _summary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class EmberPaperTheme extends _default.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "table", 'paper-table');

      _defineProperty(this, "headerWrapper", 'layout-row layout-align-space-between');

      _defineProperty(this, "tfooterInternalWrapper", 'layout-row layout-align-space-between-center footer-internal-wrapper');

      _defineProperty(this, "paginationInternalWrapper", 'layout-row layout-align-space-between-center');

      _defineProperty(this, "columnVisibleIcon", 'check_box');

      _defineProperty(this, "columnHiddenIcon", 'check_box_outline_blank');

      _defineProperty(this, "sortAscIcon", 'arrow_drop_up');

      _defineProperty(this, "sortDescIcon", 'arrow_drop_down');

      _defineProperty(this, "navFirstIcon", 'first_page');

      _defineProperty(this, "navPrevIcon", 'chevron_left');

      _defineProperty(this, "navNextIcon", 'chevron_right');

      _defineProperty(this, "navLastIcon", 'last_page');

      _defineProperty(this, "clearAllFiltersIcon", 'clear');

      _defineProperty(this, "filteringCellInternalWrapper", 'layout-row layout-align-space-between-center');

      _defineProperty(this, "columnsDropdownWrapper", 'columns-dropdown');

      _defineProperty(this, "expandRowIcon", 'expand_more');

      _defineProperty(this, "collapseAllRowsIcon", 'expand_less');

      _defineProperty(this, "expandAllRowsIcon", 'expand_more');
    }

    /**
     * @ember-paper 'models-table/themes/ember-paper/cell-content-edit'
     */
    get cellContentEditComponent() {
      return (0, _util.ensureSafeComponent)(_cellContentEdit.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/cell-edit-toggle'
     */


    get cellEditToggleComponent() {
      return (0, _util.ensureSafeComponent)(_cellEditToggle.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/columns-dropdown'
     */


    get columnsDropdownComponent() {
      return (0, _util.ensureSafeComponent)(_columnsDropdown.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/data-group-by-select'
     */


    get dataGroupBySelectComponent() {
      return (0, _util.ensureSafeComponent)(_dataGroupBySelect.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/expand-all-toggle'
     */


    get expandAllToggleComponent() {
      return (0, _util.ensureSafeComponent)(_expandAllToggle.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/expand-toggle'
     */


    get expandToggleComponent() {
      return (0, _util.ensureSafeComponent)(_expandToggle.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/global-filter'
     */


    get globalFilterComponent() {
      return (0, _util.ensureSafeComponent)(_globalFilter.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/page-size-select'
     */


    get pageSizeSelectComponent() {
      return (0, _util.ensureSafeComponent)(_pageSizeSelect.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/pagination-numeric'
     */


    get paginationNumericComponent() {
      return (0, _util.ensureSafeComponent)(_paginationNumeric.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/pagination-simple'
     */


    get paginationSimpleComponent() {
      return (0, _util.ensureSafeComponent)(_paginationSimple.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/row-filtering-cell'
     */


    get rowFilteringCellComponent() {
      return (0, _util.ensureSafeComponent)(_rowFilteringCell.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/row-select-all-checkbox'
     */


    get rowSelectAllCheckboxComponent() {
      return (0, _util.ensureSafeComponent)(_rowSelectAllCheckbox.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/row-select-checkbox'
     */


    get rowSelectCheckboxComponent() {
      return (0, _util.ensureSafeComponent)(_rowSelectCheckbox.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/select'
     */


    get selectComponent() {
      return (0, _util.ensureSafeComponent)(_select.default, this);
    }
    /**
     * @ember-paper 'models-table/themes/ember-paper/summary'
     */


    get summaryComponent() {
      return (0, _util.ensureSafeComponent)(_summary.default, this);
    }

  }

  _exports.default = EmberPaperTheme;
});