define("ember-models-table/components/models-table/themes/default/no-data", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
    <td colspan={{this.realColumnsCount}}>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{html-safe @themeInstance.noDataToShowMsg}}
      {{/if}}
    </td>
  </tr>
  */
  {
    "id": "A8URWJYS",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"td\"],[15,\"colspan\",[30,0,[\"realColumnsCount\"]]],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,3],[[30,1,[\"noDataToShowMsg\"]]],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@themeInstance\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"html-safe\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/no-data.hbs",
    "isStrictMode": false
  });

  /**
   * Table row shown when no data provided to the ModelsTable. Component used within [[DefaultTheme.TableBody | TableBody]].
   *
   * This component is a table-row with a single cell. This cell has a `colspan` equal to the shown columns count.
   *
   * Usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if Body.visibleContent.length}}
   *         {{! ... }}
   *       {{else}}
   *         <Body.NoData />
   *       {{/if}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if Body.visibleContent.length}}
   *         {{! ... }}
   *       {{else}}
   *         <Body.NoData as |ND|>
   *           Any message here
   *         </Body.NoData>
   *       {{/if}}
   *     </table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   */
  class NoData extends _component2.default {
    get realColumnsCount() {
      return this.args.columnsCount + (this.args.displayGroupedValueAs === 'column' ? 1 : 0);
    }

  }

  _exports.default = NoData;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NoData);
});