define("@jftechnology/ember-keycloak-auth/templates/components/keycloak-session-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zIIv3p39",
    "block": "[[[10,\"table\"],[14,0,\"table table-sm small\"],[12],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"minValidity\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[31,0,[\"minValidity\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"onLoad\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[31,0,[\"onLoad\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"responseMode\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[31,0,[\"responseMode\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"flow\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[31,0,[\"flow\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"checkLoginIframe\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[31,0,[\"checkLoginIframe\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"checkLoginIframeInterval\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[31,0,[\"checkLoginIframeInterval\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"idpHint\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[31,0,[\"idpHint\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"keycloakSession\"]]",
    "moduleName": "@jftechnology/ember-keycloak-auth/templates/components/keycloak-session-config.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});