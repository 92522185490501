define("ember-models-table/utils/emt/themes/ebs", ["exports", "@embroider/macros/es-compat", "@embroider/macros/runtime", "@ember/debug"], function (_exports, _esCompat, _runtime, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBsNavbar = _exports.getBsNav = _exports.getBsForm = _exports.getBsDropdown = _exports.getBsButton = void 0;

  const getBsButton = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-bootstrap v5 not found', false));
    }

    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-button")).default;
  };

  _exports.getBsButton = getBsButton;

  const getBsForm = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-bootstrap v5 not found', false));
    }

    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-form")).default;
  };

  _exports.getBsForm = getBsForm;

  const getBsDropdown = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-bootstrap v5 not found', false));
    }

    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-dropdown")).default;
  };

  _exports.getBsDropdown = getBsDropdown;

  const getBsNav = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-bootstrap v5 not found', false));
    }

    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-nav")).default;
  };

  _exports.getBsNav = getBsNav;

  const getBsNavbar = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-bootstrap v5 not found', false));
    }

    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-navbar")).default;
  };

  _exports.getBsNavbar = getBsNavbar;
});