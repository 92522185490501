define("ember-models-table/components/models-table/themes/default/select", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object/internals", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _internals, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <select
    id={{this.id}}
    {{on "change" this.onChange}}
    {{on "click" this.onClick}}
    disabled="{{if this.isDisabled "disabled"}}"
    class="{{@themeInstance.input}} {{@themeInstance.select}} {{@cssPropertyName}}">
    {{#each @options as |opt|}}
      <option
        value={{opt.value}}
        selected={{is-equal @value opt.value}}>
        {{opt.label}}
      </option>
    {{/each}}
    {{yield}}
  </select>
  */
  {
    "id": "2+jme6qN",
    "block": "[[[11,\"select\"],[16,1,[30,0,[\"id\"]]],[16,\"disabled\",[29,[[52,[30,0,[\"isDisabled\"]],\"disabled\"]]]],[16,0,[29,[[30,1,[\"input\"]],\" \",[30,1,[\"select\"]],\" \",[30,2]]]],[4,[38,1],[\"change\",[30,0,[\"onChange\"]]],null],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,3]],null]],null],null,[[[1,\"    \"],[10,\"option\"],[15,2,[30,4,[\"value\"]]],[15,\"selected\",[28,[37,4],[[30,5],[30,4,[\"value\"]]],null]],[12],[1,\"\\n      \"],[1,[30,4,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[4]],null],[1,\"  \"],[18,6,null],[1,\"\\n\"],[13]],[\"@themeInstance\",\"@cssPropertyName\",\"@options\",\"opt\",\"@value\",\"&default\"],false,[\"if\",\"on\",\"each\",\"-track-array\",\"is-equal\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/select.hbs",
    "isStrictMode": false
  });

  /**
   * Select-dropdown with a list of options. Used as page-size select and for select-filters.
   */
  let Select = (_class = class Select extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_id", _descriptor, this);
    }

    get id() {
      var _this$args$id;

      return (_this$args$id = this.args.id) !== null && _this$args$id !== void 0 ? _this$args$id : this._id;
    }

    get isDisabled() {
      return !this.args.options || !this.args.options.length;
    }

    onChange(e) {
      var _e$target;

      e.stopPropagation();
      const value = (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.value;
      let valueToUse = value;

      if (this.args.type === 'number') {
        valueToUse = +value;
      }

      this.args.changeValue(valueToUse);
      return false;
    }

    onClick(e) {
      var _e$stopPropagation;

      e === null || e === void 0 ? void 0 : (_e$stopPropagation = e.stopPropagation) === null || _e$stopPropagation === void 0 ? void 0 : _e$stopPropagation.call(e);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _internals.guidFor)(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = Select;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Select);
});