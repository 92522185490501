define("ember-models-table/components/models-table/themes/ember-bootstrap-v5/page-size-select", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/page-size-select", "ember-models-table/utils/emt/themes/ebs"], function (_exports, _component, _templateFactory, _util, _pageSizeSelect, _ebs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.pageSizeWrapper}}">
    {{#let
      (hash
        Select=(
          component (ensure-safe-component @themeInstance.selectComponent)
          id=this.inputId
          options=@pageSizeOptions
          value=@pageSize
          type=@type
          themeInstance=@themeInstance
          cssPropertyName="changePageSize"
          changeValue=@changePageSize
        )
      )
    as |PageSizeSelect|
    }}
      {{#if (has-block)}}
        {{yield PageSizeSelect}}
      {{else}}
        <this.BSForm class="pull-left">
          <div class="input-group">
            <label for={{this.inputId}} class="input-group-text">{{@themeInstance.rowsCountMsg}}</label>
            <PageSizeSelect.Select/>
          </div>
        </this.BSForm>
      {{/if}}
    {{/let}}
  </div>
  */
  {
    "id": "lzRhhZoj",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"pageSizeWrapper\"]]]]],[12],[1,\"\\n\"],[44,[[28,[37,1],null,[[\"Select\"],[[50,[28,[37,3],[[30,1,[\"selectComponent\"]]],null],0,null,[[\"id\",\"options\",\"value\",\"type\",\"themeInstance\",\"cssPropertyName\",\"changeValue\"],[[30,0,[\"inputId\"]],[30,2],[30,3],[30,4],[30,1],\"changePageSize\",[30,5]]]]]]]],[[[41,[48,[30,7]],[[[1,\"      \"],[18,7,[[30,6]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[30,0,[\"BSForm\"]],[[24,0,\"pull-left\"]],null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[14,0,\"input-group-text\"],[12],[1,[30,1,[\"rowsCountMsg\"]]],[13],[1,\"\\n          \"],[8,[30,6,[\"Select\"]],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]]],[6]]],[13]],[\"@themeInstance\",\"@pageSizeOptions\",\"@pageSize\",\"@type\",\"@changePageSize\",\"PageSizeSelect\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"ensure-safe-component\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-bootstrap-v5/page-size-select.hbs",
    "isStrictMode": false
  });

  class PageSizeSelect extends _pageSizeSelect.default {
    get BSForm() {
      return (0, _util.ensureSafeComponent)((0, _ebs.getBsForm)(), this);
    }

  }

  _exports.default = PageSizeSelect;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageSizeSelect);
});