define("ember-models-table/components/models-table/themes/default/summary", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/internals", "@ember/object", "ember-models-table/utils/emt/fmt"], function (_exports, _component, _templateFactory, _component2, _internals, _object, _fmt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.footerSummary}} {{this.paginationTypeClass}}">
    {{#if (has-block)}}
      {{yield
        (hash
          summary=this.summary
        )
      }}
    {{else}}
      <form class={{@themeInstance.form}}>
        <div class={{@themeInstance.formElementWrapper}}>
          <label for={{this.inputId}}>
            {{this.summary}}
            <a
              href="#"
              type="button"
              class="clearFilters {{unless @anyFilterUsed "hidden"}}"
              {{on "click" this.doClearFilters}}>
              <i class={{@themeInstance.clearAllFiltersIcon}}></i>
              <span class="emt-sr-only">{{@themeInstance.clearAllFiltersMsg}}</span>
            </a>
          </label>
          {{! just a small hack to align label vertically }}
          <input id={{this.inputId}} class="invisible {{@themeInstance.input}}">
        </div>
      </form>
    {{/if}}
  </div>
  */
  {
    "id": "UDcZyBtF",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"footerSummary\"]],\" \",[30,0,[\"paginationTypeClass\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[28,[37,3],null,[[\"summary\"],[[30,0,[\"summary\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"form\"],[15,0,[30,1,[\"form\"]]],[12],[1,\"\\n      \"],[10,0],[15,0,[30,1,[\"formElementWrapper\"]]],[12],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,\"\\n          \"],[1,[30,0,[\"summary\"]]],[1,\"\\n          \"],[11,3],[24,6,\"#\"],[16,0,[29,[\"clearFilters \",[52,[51,[30,2]],\"hidden\"]]]],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"doClearFilters\"]]],null],[12],[1,\"\\n            \"],[10,\"i\"],[15,0,[30,1,[\"clearAllFiltersIcon\"]]],[12],[13],[1,\"\\n            \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,1,[\"clearAllFiltersMsg\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"],[1,\"        \"],[10,\"input\"],[15,1,[30,0,[\"inputId\"]]],[15,0,[29,[\"invisible \",[30,1,[\"input\"]]]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@themeInstance\",\"@anyFilterUsed\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"unless\",\"on\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/summary.hbs",
    "isStrictMode": false
  });

  /**
   * Summary block used within [[DefaultTheme.Footer]].
   *
   * Usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.Summary />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * References to the following properties are yielded:
   *
   * * [[summary]] - message like "Show xx - yy from zzz"
   */
  let Summary = (_class = class Summary extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "elementId", (0, _internals.guidFor)(this));
    }

    get paginationTypeClass() {
      return this.args.useNumericPagination ? this.args.themeInstance.footerSummaryNumericPagination : this.args.themeInstance.footerSummaryDefaultPagination;
    }
    /**
     * Summary message like `A - B of C`
     */


    get summary() {
      return (0, _fmt.default)(this.args.themeInstance.tableSummaryMsg, this.args.firstIndex, this.args.lastIndex, this.args.recordsCount);
    }

    get inputId() {
      return `${this.elementId}-summary-input`;
    }
    /**
     * @event doClearFilters
     */


    doClearFilters(e) {
      var _e$stopPropagation, _e$preventDefault;

      e === null || e === void 0 ? void 0 : (_e$stopPropagation = e.stopPropagation) === null || _e$stopPropagation === void 0 ? void 0 : _e$stopPropagation.call(e);
      e === null || e === void 0 ? void 0 : (_e$preventDefault = e.preventDefault) === null || _e$preventDefault === void 0 ? void 0 : _e$preventDefault.call(e);
      this.args.clearFilters();
    }
    /**
     * @event noop
     */


    noop() {// do nothing.
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "doClearFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doClearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "noop"), _class.prototype)), _class);
  _exports.default = Summary;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Summary);
});