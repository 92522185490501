define("ember-models-table/utils/emt/array", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.intersection = void 0;

  // from https://github.com/emberjs/ember.js/blob/v3.24.2/packages/%40ember/object/lib/computed/reduce_computed_macros.js#L1062
  const intersection = function () {
    for (var _len = arguments.length, arrays = new Array(_len), _key = 0; _key < _len; _key++) {
      arrays[_key] = arguments[_key];
    }

    if (!arrays.length) {
      return (0, _array.A)([]);
    }

    const results = (arrays.pop() || []).filter(candidate => {
      for (let i = 0; i < arrays.length; i++) {
        let found = false;
        const array = arrays[i] || [];

        for (let j = 0; j < array.length; j++) {
          if (array[j] === candidate) {
            found = true;
            break;
          }
        }

        if (!found) {
          return false;
        }
      }

      return true;
    });
    return (0, _array.A)(results);
  };

  _exports.intersection = intersection;
});