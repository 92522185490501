define("ember-models-table/components/models-table/themes/bootstrap3/row-select-all-checkbox", ["exports", "@ember/component", "@ember/template-factory", "ember-models-table/components/models-table/themes/default/row-select-all-checkbox"], function (_exports, _component, _templateFactory, _rowSelectAllCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    onclick={{this.doToggleAllSelection}}
    type="button"
    class="toggle-all {{@themeInstance.buttonLink}}">
    {{#if (and @selectedItems.length (not-eq @selectedItems.length @data.length))}}
      <span class="emt-icons-stack">
        <i class={{@themeInstance.selectSomeRowsIcon}}></i>
        <i class={{@themeInstance.deselectAllRowsIcon}}></i>
      </span>
      &nbsp;
    {{else}}
      <i class={{if
        (is-equal @selectedItems.length @data.length)
        @themeInstance.selectAllRowsIcon
        @themeInstance.deselectAllRowsIcon
      }}></i>
    {{/if}}
  </button>
  {{yield}}
  */
  {
    "id": "QSMaUi7f",
    "block": "[[[10,\"button\"],[15,\"onclick\",[30,0,[\"doToggleAllSelection\"]]],[15,0,[29,[\"toggle-all \",[30,1,[\"buttonLink\"]]]]],[14,4,\"button\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,2,[\"length\"]],[28,[37,2],[[30,2,[\"length\"]],[30,3,[\"length\"]]],null]],null],[[[1,\"    \"],[10,1],[14,0,\"emt-icons-stack\"],[12],[1,\"\\n      \"],[10,\"i\"],[15,0,[30,1,[\"selectSomeRowsIcon\"]]],[12],[13],[1,\"\\n      \"],[10,\"i\"],[15,0,[30,1,[\"deselectAllRowsIcon\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n     \\n\"]],[]],[[[1,\"    \"],[10,\"i\"],[15,0,[52,[28,[37,3],[[30,2,[\"length\"]],[30,3,[\"length\"]]],null],[30,1,[\"selectAllRowsIcon\"]],[30,1,[\"deselectAllRowsIcon\"]]]],[12],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[18,4,null]],[\"@themeInstance\",\"@selectedItems\",\"@data\",\"&default\"],false,[\"if\",\"and\",\"not-eq\",\"is-equal\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/bootstrap3/row-select-all-checkbox.hbs",
    "isStrictMode": false
  });

  class RowSelectAllCheckbox extends _rowSelectAllCheckbox.default {}

  _exports.default = RowSelectAllCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowSelectAllCheckbox);
});