define("ember-models-table/components/models-table/themes/ember-paper/page-size-select", ["exports", "@ember/component", "@ember/template-factory", "ember-models-table/components/models-table/themes/default/page-size-select"], function (_exports, _component, _templateFactory, _pageSizeSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let
    (hash
      Select=(
        component (ensure-safe-component @themeInstance.selectComponent)
        id=this.inputId
        options=@pageSizeOptions
        value=@pageSize
        type=@type
        label=@themeInstance.rowsCountMsg
        themeInstance=@themeInstance
        cssPropertyName="changePageSize"
        changeValue=@changePageSize
      )
    )
  as |PageSizeSelect|
  }}
    {{#if (has-block)}}
      {{yield PageSizeSelect}}
    {{else}}
      <PageSizeSelect.Select/>
    {{/if}}
  {{/let}}
  
  */
  {
    "id": "BhjuoROp",
    "block": "[[[44,[[28,[37,1],null,[[\"Select\"],[[50,[28,[37,3],[[30,1,[\"selectComponent\"]]],null],0,null,[[\"id\",\"options\",\"value\",\"type\",\"label\",\"themeInstance\",\"cssPropertyName\",\"changeValue\"],[[30,0,[\"inputId\"]],[30,2],[30,3],[30,4],[30,1,[\"rowsCountMsg\"]],[30,1],\"changePageSize\",[30,5]]]]]]]],[[[41,[48,[30,7]],[[[1,\"    \"],[18,7,[[30,6]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[30,6,[\"Select\"]],null,null,null],[1,\"\\n\"]],[]]]],[6]]]],[\"@themeInstance\",\"@pageSizeOptions\",\"@pageSize\",\"@type\",\"@changePageSize\",\"PageSizeSelect\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"ensure-safe-component\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/page-size-select.hbs",
    "isStrictMode": false
  });

  class PageSizeSelect extends _pageSizeSelect.default {}

  _exports.default = PageSizeSelect;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageSizeSelect);
});