define("ember-models-table/utils/emt/emt-column", ["exports", "@glimmer/tracking", "@ember/array", "@ember/utils", "@ember/debug", "@ember/string", "ember-models-table/constants/sort-constants"], function (_exports, _tracking, _array, _utils, _debug, _string, _sortConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.propertyNameToTitle = _exports.optionStrToObj = _exports.defaultFilter = _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const hasOwnProperty = Object.prototype.hasOwnProperty;

  /*
   * Default filter-function used in the filter by columns
   *
   * @param {string} cellValue value in the table cell
   * @param {string} filterString needed substring
   * @return boolean
   */
  const defaultFilter = (cellValue, filterString) => cellValue.includes(filterString);

  _exports.defaultFilter = defaultFilter;

  const propertyNameToTitle = name => name && (0, _string.capitalize)((0, _string.dasherize)(name).replace(/-/g, ' '));

  _exports.propertyNameToTitle = propertyNameToTitle;

  const optionStrToObj = option => ({
    value: option,
    label: option
  });

  _exports.optionStrToObj = optionStrToObj;
  let ModelsTableColumn = (_class = class ModelsTableColumn {
    get columnTitle() {
      return this.title || propertyNameToTitle(this.filterField);
    }

    get cssPropertyName() {
      return this.propertyName ? this.propertyName.replace(/\./g, '-') : '';
    }

    get isVisible() {
      return !this.isHidden;
    }

    get sortAsc() {
      return this.sorting === _sortConstants.SortConstants.asc;
    }

    get sortDesc() {
      return this.sorting === _sortConstants.SortConstants.desc;
    }

    get filterUsed() {
      return !!this.filterString;
    }

    get useSorting() {
      return !!this.sortField && !this.disableSorting;
    }

    get sortField() {
      return this.sortedBy || this.propertyName;
    }

    get useFilter() {
      const useFilter = !!this.filterField && !this.disableFiltering;

      if (this.filterWithSelect) {
        return this.usePredefinedFilterOptions ? !!this.mappedPredefinedFilterOptions : useFilter;
      }

      return useFilter;
    }

    get filterField() {
      return this.filteredBy || this.propertyName;
    }

    get mappedPredefinedFilterOptions() {
      if (this.filterWithSelect && this.filterField && !this.disableFiltering) {
        const predefinedFilterOptions = this.predefinedFilterOptions || [];
        const usePredefinedFilterOptions = (0, _array.isArray)(predefinedFilterOptions);

        if (usePredefinedFilterOptions && predefinedFilterOptions.length) {
          var _mappedPredefinedFilt;

          const allOptionsAreObjects = (0, _array.A)(predefinedFilterOptions).every(option => typeof option === 'object' && hasOwnProperty.call(option, 'label') && hasOwnProperty.call(option, 'value'));
          const allOptionsArePrimitives = (0, _array.A)(predefinedFilterOptions).every(option => typeof option !== 'object');
          (true && !(allOptionsAreObjects || allOptionsArePrimitives) && (0, _debug.assert)('`predefinedFilterOptions` should be an array of objects or primitives and not mixed', allOptionsAreObjects || allOptionsArePrimitives));
          let mappedPredefinedFilterOptions;

          if (allOptionsArePrimitives) {
            mappedPredefinedFilterOptions = predefinedFilterOptions.map(opt => typeof opt === 'object' ? opt : optionStrToObj(opt));
          } else {
            mappedPredefinedFilterOptions = predefinedFilterOptions;
          }

          if ((0, _array.isArray)(mappedPredefinedFilterOptions) && '' !== ((_mappedPredefinedFilt = mappedPredefinedFilterOptions[0]) === null || _mappedPredefinedFilt === void 0 ? void 0 : _mappedPredefinedFilt.value)) {
            var _this$filterPlacehold;

            mappedPredefinedFilterOptions = [{
              value: '',
              label: (_this$filterPlacehold = this.filterPlaceholder) !== null && _this$filterPlacehold !== void 0 ? _this$filterPlacehold : ''
            }, ...mappedPredefinedFilterOptions];
          }

          return usePredefinedFilterOptions ? (0, _array.A)(mappedPredefinedFilterOptions || []) : (0, _array.A)([]);
        }
      }

      return null;
    }

    get filterOptions() {
      if (!this.filterField) {
        return null;
      }

      if (this.usePredefinedFilterOptions) {
        return this.mappedPredefinedFilterOptions ? (0, _array.A)(this.mappedPredefinedFilterOptions) : null;
      }

      if (this.filterWithSelect && 'array' !== (0, _utils.typeOf)(this.mappedPredefinedFilterOptions)) {
        const _data = (0, _array.A)(this.data.compact());

        let options = (0, _array.A)(_data.mapBy(this.filterField)).compact();

        if (this.sortFilterOptions) {
          options = options.sort();
        }

        const filterOptions = (0, _array.A)((0, _array.A)(['', ...options]).uniq().map(optionStrToObj));

        if (this.filterPlaceholder && filterOptions[0] && !filterOptions[0].label) {
          filterOptions[0].label = this.filterPlaceholder;
        }

        return filterOptions;
      }

      return null;
    }

    constructor(options) {
      _initializerDefineProperty(this, "propertyName", _descriptor, this);

      _initializerDefineProperty(this, "title", _descriptor2, this);

      _initializerDefineProperty(this, "simple", _descriptor3, this);

      _initializerDefineProperty(this, "component", _descriptor4, this);

      _initializerDefineProperty(this, "componentForEdit", _descriptor5, this);

      _initializerDefineProperty(this, "editable", _descriptor6, this);

      _initializerDefineProperty(this, "componentForFilterCell", _descriptor7, this);

      _initializerDefineProperty(this, "componentForSortCell", _descriptor8, this);

      _initializerDefineProperty(this, "componentForFooterCell", _descriptor9, this);

      _initializerDefineProperty(this, "colspanForSortCell", _descriptor10, this);

      _initializerDefineProperty(this, "realColspanForSortCell", _descriptor11, this);

      _initializerDefineProperty(this, "colspanForFilterCell", _descriptor12, this);

      _initializerDefineProperty(this, "realColspanForFilterCell", _descriptor13, this);

      _initializerDefineProperty(this, "sortedBy", _descriptor14, this);

      _initializerDefineProperty(this, "sortDirection", _descriptor15, this);

      _initializerDefineProperty(this, "sortPrecedence", _descriptor16, this);

      _initializerDefineProperty(this, "disableSorting", _descriptor17, this);

      _initializerDefineProperty(this, "disableFiltering", _descriptor18, this);

      _initializerDefineProperty(this, "filterString", _descriptor19, this);

      _initializerDefineProperty(this, "filteredBy", _descriptor20, this);

      _initializerDefineProperty(this, "sorting", _descriptor21, this);

      _initializerDefineProperty(this, "isHidden", _descriptor22, this);

      _initializerDefineProperty(this, "mayBeHidden", _descriptor23, this);

      _initializerDefineProperty(this, "filterWithSelect", _descriptor24, this);

      _initializerDefineProperty(this, "sortFilterOptions", _descriptor25, this);

      _initializerDefineProperty(this, "className", _descriptor26, this);

      _initializerDefineProperty(this, "filterPlaceholder", _descriptor27, this);

      _initializerDefineProperty(this, "routeName", _descriptor28, this);

      _initializerDefineProperty(this, "routeProperty", _descriptor29, this);

      _initializerDefineProperty(this, "usePredefinedFilterOptions", _descriptor30, this);

      _initializerDefineProperty(this, "predefinedFilterOptions", _descriptor31, this);

      _initializerDefineProperty(this, "filterFunction", _descriptor32, this);

      _initializerDefineProperty(this, "sortFunction", _descriptor33, this);

      _initializerDefineProperty(this, "originalDefinition", _descriptor34, this);

      _initializerDefineProperty(this, "data", _descriptor35, this);

      _initializerDefineProperty(this, "defaultVisible", _descriptor36, this);

      for (const k in options) {
        if (hasOwnProperty.call(options, k)) {
          this[k] = options[k];
        }
      }

      this.defaultVisible = !this.isHidden;
      this.originalDefinition = options;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "propertyName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "simple", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "component", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "componentForEdit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "editable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "componentForFilterCell", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "componentForSortCell", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "componentForFooterCell", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "colspanForSortCell", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "realColspanForSortCell", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "colspanForFilterCell", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "realColspanForFilterCell", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "sortedBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "sortDirection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _sortConstants.SortConstants.none;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "sortPrecedence", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "disableSorting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "disableFiltering", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "filterString", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "filteredBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "sorting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _sortConstants.SortConstants.none;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "isHidden", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "mayBeHidden", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "filterWithSelect", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "sortFilterOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "className", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "filterPlaceholder", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "routeName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "routeProperty", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'id';
    }
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "usePredefinedFilterOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "predefinedFilterOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "filterFunction", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "sortFunction", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "originalDefinition", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "defaultVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ModelsTableColumn;
});