define("ember-models-table/services/emt-themes/ember-bootstrap-v4", ["exports", "@embroider/util", "ember-models-table/services/emt-themes/bootstrap4", "ember-models-table/components/models-table/themes/ember-bootstrap-v4/columns-dropdown", "ember-models-table/components/models-table/themes/ember-bootstrap-v4/data-group-by-select", "ember-models-table/components/models-table/themes/ember-bootstrap-v4/global-filter", "ember-models-table/components/models-table/themes/ember-bootstrap-v4/row-filtering-cell", "ember-models-table/components/models-table/themes/ember-bootstrap-v4/summary"], function (_exports, _util, _bootstrap, _columnsDropdown, _dataGroupBySelect, _globalFilter, _rowFilteringCell, _summary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class EmberBootstrap4Theme extends _bootstrap.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "sortGroupedPropertyBtn", 'btn btn-link');
    }

    /**
     * @default 'models-table/themes/ember-bootstrap-v4/columns-dropdown'
     */
    get columnsDropdownComponent() {
      return (0, _util.ensureSafeComponent)(_columnsDropdown.default, this);
    }
    /**
     * @default 'models-table/themes/ember-bootstrap-v4/data-group-by-select'
     */


    get dataGroupBySelectComponent() {
      return (0, _util.ensureSafeComponent)(_dataGroupBySelect.default, this);
    }
    /**
     * @default 'models-table/themes/ember-bootstrap-v4/global-filter'
     */


    get globalFilterComponent() {
      return (0, _util.ensureSafeComponent)(_globalFilter.default, this);
    }
    /**
     * @default 'models-table/themes/ember-bootstrap-v4/row-filtering-cell'
     */


    get rowFilteringCellComponent() {
      return (0, _util.ensureSafeComponent)(_rowFilteringCell.default, this);
    }
    /**
     * @default 'models-table/themes/ember-bootstrap-v4/summary'
     */


    get summaryComponent() {
      return (0, _util.ensureSafeComponent)(_summary.default, this);
    }

  }

  _exports.default = EmberBootstrap4Theme;
});