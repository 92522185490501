define("ember-models-table/components/models-table/themes/ember-paper/cell-content-edit", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/cell-content-edit", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _cellContentEdit, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (get @record @column.propertyName) as |value|}}
    <this.PaperInput @value={{this.value}} @onChange={{fn (mut value)}}/>
  {{/let}}
  {{yield}}
  */
  {
    "id": "FCQ0lII0",
    "block": "[[[44,[[28,[37,1],[[30,1],[30,2,[\"propertyName\"]]],null]],[[[1,\"  \"],[8,[30,0,[\"PaperInput\"]],null,[[\"@value\",\"@onChange\"],[[30,0,[\"value\"]],[28,[37,2],[[28,[37,3],[[30,3]],null]],null]]],null],[1,\"\\n\"]],[3]]],[18,4,null]],[\"@record\",\"@column\",\"value\",\"&default\"],false,[\"let\",\"get\",\"fn\",\"mut\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/cell-content-edit.hbs",
    "isStrictMode": false
  });

  class CellContentEdit extends _cellContentEdit.default {
    get PaperInput() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperInput)(), this);
    }

  }

  _exports.default = CellContentEdit;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CellContentEdit);
});