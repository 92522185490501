define("ember-models-table/components/models-table/themes/plain-html/global-filter", ["exports", "@ember/component", "@ember/template-factory", "ember-models-table/components/models-table/themes/default/global-filter"], function (_exports, _component, _templateFactory, _globalFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.globalFilterWrapper}}">
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      <div class="globalSearch">
        {{#if @themeInstance.searchLabelMsg}}
          <label for={{this.inputId}}>{{@themeInstance.searchLabelMsg}}</label>
        {{/if}}
        <Input
          id={{this.inputId}}
          class="filterString {{@themeInstance.input}}"
          @value={{@value}}
          placeholder={{@themeInstance.searchPlaceholderMsg}}
          {{on "input" this.updateGlobalFilterString}}/>
        <button
          type="button"
          class="clearFilterIcon"
          disabled={{unless @globalFilterUsed "disabled"}}
          onClick={{this.clearGlobalFilter}}>
          <i class={{@themeInstance.clearFilterIcon}}></i>
          <span class="emt-sr-only">{{@themeInstance.clearGlobalFilterMsg}}</span>
        </button>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "AgpopeiM",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"globalFilterWrapper\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"globalSearch\"],[12],[1,\"\\n\"],[41,[30,1,[\"searchLabelMsg\"]],[[[1,\"        \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,[30,1,[\"searchLabelMsg\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[39,3],[[16,1,[30,0,[\"inputId\"]]],[16,0,[29,[\"filterString \",[30,1,[\"input\"]]]]],[16,\"placeholder\",[30,1,[\"searchPlaceholderMsg\"]]],[4,[38,4],[\"input\",[30,0,[\"updateGlobalFilterString\"]]],null]],[[\"@value\"],[[30,2]]],null],[1,\"\\n      \"],[10,\"button\"],[14,0,\"clearFilterIcon\"],[15,\"disabled\",[52,[51,[30,3]],\"disabled\"]],[15,\"onClick\",[30,0,[\"clearGlobalFilter\"]]],[14,4,\"button\"],[12],[1,\"\\n        \"],[10,\"i\"],[15,0,[30,1,[\"clearFilterIcon\"]]],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,1,[\"clearGlobalFilterMsg\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@themeInstance\",\"@value\",\"@globalFilterUsed\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"on\",\"unless\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/plain-html/global-filter.hbs",
    "isStrictMode": false
  });

  class GlobalFilter extends _globalFilter.default {}

  _exports.default = GlobalFilter;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GlobalFilter);
});