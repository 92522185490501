define("ember-models-table/components/models-table/themes/ember-paper/summary", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/summary", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _summary, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.footerSummary}} {{this.paginationTypeClass}}">
    {{#if (has-block)}}
      {{yield
        (hash
          summary=this.summary
        )
      }}
    {{else}}
      {{this.summary}}
      <this.PaperButton
        class="clearFilters"
        @disabled={{unless @anyFilterUsed "disabled"}}
        @onClick={{this.doClearFilters}}>
        <this.PaperIcon @icon={{@themeInstance.clearAllFiltersIcon}}>
          <span class="emt-sr-only">{{@themeInstance.clearAllFiltersMsg}}</span>
        </this.PaperIcon>
      </this.PaperButton>
    {{/if}}
  </div>
  */
  {
    "id": "B/8AY+jf",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"footerSummary\"]],\" \",[30,0,[\"paginationTypeClass\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[28,[37,3],null,[[\"summary\"],[[30,0,[\"summary\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"summary\"]]],[1,\"\\n    \"],[8,[30,0,[\"PaperButton\"]],[[24,0,\"clearFilters\"]],[[\"@disabled\",\"@onClick\"],[[52,[51,[30,2]],\"disabled\"],[30,0,[\"doClearFilters\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,0,[\"PaperIcon\"]],null,[[\"@icon\"],[[30,1,[\"clearAllFiltersIcon\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,1,[\"clearAllFiltersMsg\"]]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"@themeInstance\",\"@anyFilterUsed\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"unless\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/summary.hbs",
    "isStrictMode": false
  });

  class Summary extends _summary.default {
    get PaperButton() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperButton)(), this);
    }

    get PaperIcon() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperIcon)(), this);
    }

  }

  _exports.default = Summary;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Summary);
});