define("ember-models-table/components/models-table/themes/default/cell-column-summary", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td>
    {{yield
      (
        hash
          minSelected=this.minSelected
          maxSelected=this.maxSelected
          sumSelected=this.sumSelected
          avgSelected=this.avgSelected
          medianSelected=this.medianSelected
          minData=this.minData
          maxData=this.maxData
          sumData=this.sumData
          avgData=this.avgData
          medianData=this.medianData
      )
    }}
  </td>
  */
  {
    "id": "feyxgrBu",
    "block": "[[[10,\"td\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"minSelected\",\"maxSelected\",\"sumSelected\",\"avgSelected\",\"medianSelected\",\"minData\",\"maxData\",\"sumData\",\"avgData\",\"medianData\"],[[30,0,[\"minSelected\"]],[30,0,[\"maxSelected\"]],[30,0,[\"sumSelected\"]],[30,0,[\"avgSelected\"]],[30,0,[\"medianSelected\"]],[30,0,[\"minData\"]],[30,0,[\"maxData\"]],[30,0,[\"sumData\"]],[30,0,[\"avgData\"]],[30,0,[\"medianData\"]]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/cell-column-summary.hbs",
    "isStrictMode": false
  });

  const sumBy = collection => collection ? collection.reduce((a, b) => a + b, 0) : 0;

  const avgBy = (collection, avg) => {
    const count = collection.length;
    return count ? avg / count : 0;
  };

  const minBy = collection => collection ? Math.min(...collection) : null;

  const maxBy = collection => collection ? Math.max(...collection) : null;

  const medianBy = collection => {
    if (!collection.length) {
      return null;
    }

    collection = collection.slice().sort((a, b) => a - b);
    const lowMiddle = Math.floor((collection.length - 1) / 2);
    const highMiddle = Math.ceil((collection.length - 1) / 2);
    return ((collection[lowMiddle] || 0) + (collection[highMiddle] || 0)) / 2;
  };

  /**
   * Component for table-footer cells. Used as column-summary.
   *
   * It yields several properties:
   *
   * * [[DefaultTheme.CellColumnSummary.minSelected | minSelected]]
   * * [[DefaultTheme.CellColumnSummary.maxSelected | maxSelected]]
   * * [[DefaultTheme.CellColumnSummary.sumSelected | sumSelected]]
   * * [[DefaultTheme.CellColumnSummary.avgSelected | avgSelected]]
   * * [[DefaultTheme.CellColumnSummary.medianSelected | medianSelected]]
   * * [[DefaultTheme.CellColumnSummary.minData | minData]]
   * * [[DefaultTheme.CellColumnSummary.maxData | maxData]]
   * * [[DefaultTheme.CellColumnSummary.sumData | sumData]]
   * * [[DefaultTheme.CellColumnSummary.avgData | avgData]]
   * * [[DefaultTheme.CellColumnSummary.medianData | medianData]]
   *
   * Component should be used only for column with set `propertyName`.
   *
   * Component should be extended.
   */
  class CellColumnSummary extends _component2.default {
    /**
     * Min of mapped `selectedItems`
     */
    get minSelected() {
      return minBy(this.args.mappedSelectedItems);
    }
    /**
     * Min of mapped `data`
     */


    get minData() {
      return minBy(this.args.mappedData);
    }
    /**
     * Max of mapped `selectedItems`
     */


    get maxSelected() {
      return maxBy(this.args.mappedSelectedItems);
    }
    /**
     * Max of mapped `data`
     */


    get maxData() {
      return maxBy(this.args.mappedData);
    }
    /**
     * Sum of mapped `selectedItems`
     */


    get sumSelected() {
      return sumBy(this.args.mappedSelectedItems);
    }
    /**
     * Sum of mapped `data`
     */


    get sumData() {
      return sumBy(this.args.mappedData);
    }
    /**
     * Average of mapped `selectedItems`
     */


    get avgSelected() {
      return avgBy(this.args.mappedSelectedItems, this.sumSelected);
    }
    /**
     * Average of mapped `data`
     */


    get avgData() {
      return avgBy(this.args.mappedData, this.sumData);
    }
    /**
     * Median of mapped `selectedItems`
     */


    get medianSelected() {
      return medianBy(this.args.mappedSelectedItems);
    }
    /**
     * Median of mapped `data`
     */


    get medianData() {
      return medianBy(this.args.mappedData);
    }

  }

  _exports.default = CellColumnSummary;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CellColumnSummary);
});