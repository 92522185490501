define("@jftechnology/ember-keycloak-auth/mixins/keycloak-adapter", ["exports", "@ember/object/mixin", "@ember/object", "@ember/service"], function (_exports, _mixin, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember Mixin that can be combined with an ember-data adapter. It is intended to..
   * (i) check that the keycloak session is fresh immediately before a call to the secured back end, and
   * (ii) add an Authorization header into any calls made via the adapter to the secured back end.
   * If the session check fails this mixin will throw an error - it will not redirect the user to the login page.
   *
   * @class KeycloakAdapterMixin
   * @public
   */
  var _default = _mixin.default.create({
    /**
     * An injected keycloak session.
     *
     * @property keycloakSession
     * @type {KeycloakSession}
     */
    keycloakSession: (0, _service.inject)(),
    headers: (0, _object.computed)('keycloakSession.timestamp', function () {
      let authorization = `Bearer ${this.get('keycloakSession.token')}`;
      return {
        'Authorization': `${authorization}`
      };
    }),

    /**
     * Will overload the adapter method to ensure that the call to the secured back end is made only after the session token has been updated.
     *
     * @method ajax
     * @param url {String}
     * @param type {String}
     * @param hash {Object}
     * @private
     */
    ajax(url, type, hash) {
      let self = this;
      let ajax = this._super;
      return this.keycloakSession.updateToken().then(() =>
      /* We have a valid token - call the super method */
      ajax.apply(self, [url, type, hash]), reason => {
        console.warn(`Keycloak adapter mixin :: ajax :: rejected :: ${reason}`);
        throw reason;
      });
    }

  });

  _exports.default = _default;
});