define("ember-models-table/utils/emt/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.splitPropSortDirection = void 0;

  const splitPropSortDirection = function () {
    let propSortDirection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    const chunks = propSortDirection.split(':');
    const sortDirection = chunks.pop() || '';
    const propName = chunks.join(':');
    return [propName, sortDirection];
  };

  _exports.splitPropSortDirection = splitPropSortDirection;
});