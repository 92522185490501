define("ember-models-table/components/models-table/themes/ember-bootstrap-v4/global-filter", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/global-filter", "ember-models-table/utils/emt/themes/ebs"], function (_exports, _component, _templateFactory, _util, _globalFilter, _ebs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.globalFilterWrapper}}">
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      <div class="globalSearch input-group">
        {{#if @themeInstance.searchLabelMsg}}
          <div class="input-group-prepend">
            <label for={{this.inputId}} class="input-group-text">{{@themeInstance.searchLabelMsg}}</label>
          </div>
        {{/if}}
        <Input
          id={{this.inputId}}
          @type="text"
          @value={{@value}}
          class="filterString {{@themeInstance.input}}"
          @enter={{this.noop}}
          {{on "input" this.updateGlobalFilterString}}
          placeholder={{@themeInstance.searchPlaceholderMsg}}/>
        <div class="input-group-append">
          <this.BsButton
            class="clearFilterIcon {{@themeInstance.buttonLink}}"
            disabled={{unless @globalFilterUsed "disabled"}}
            @outline={{true}}
            @onClick={{this.clearGlobalFilter}}>
            <i class={{@themeInstance.clearFilterIcon}}></i>
            <span class="emt-sr-only">{{@themeInstance.clearGlobalFilterMsg}}</span>
          </this.BsButton>
        </div>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "0+C2i1Qu",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"globalFilterWrapper\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"globalSearch input-group\"],[12],[1,\"\\n\"],[41,[30,1,[\"searchLabelMsg\"]],[[[1,\"        \"],[10,0],[14,0,\"input-group-prepend\"],[12],[1,\"\\n          \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[14,0,\"input-group-text\"],[12],[1,[30,1,[\"searchLabelMsg\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[39,3],[[16,1,[30,0,[\"inputId\"]]],[16,0,[29,[\"filterString \",[30,1,[\"input\"]]]]],[16,\"placeholder\",[30,1,[\"searchPlaceholderMsg\"]]],[4,[38,4],[\"input\",[30,0,[\"updateGlobalFilterString\"]]],null]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[30,2],[30,0,[\"noop\"]]]],null],[1,\"\\n      \"],[10,0],[14,0,\"input-group-append\"],[12],[1,\"\\n        \"],[8,[30,0,[\"BsButton\"]],[[16,0,[29,[\"clearFilterIcon \",[30,1,[\"buttonLink\"]]]]],[16,\"disabled\",[52,[51,[30,3]],\"disabled\"]]],[[\"@outline\",\"@onClick\"],[true,[30,0,[\"clearGlobalFilter\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,\"i\"],[15,0,[30,1,[\"clearFilterIcon\"]]],[12],[13],[1,\"\\n          \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,1,[\"clearGlobalFilterMsg\"]]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@themeInstance\",\"@value\",\"@globalFilterUsed\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"on\",\"unless\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-bootstrap-v4/global-filter.hbs",
    "isStrictMode": false
  });

  class GlobalFilter extends _globalFilter.default {
    get BsButton() {
      return (0, _util.ensureSafeComponent)((0, _ebs.getBsButton)(), this);
    }

  }

  _exports.default = GlobalFilter;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GlobalFilter);
});