define("ember-models-table/components/models-table/themes/default/group-summary-row", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class="group-summary-row">
    {{yield}}
  </tr>
  */
  {
    "id": "TXxxz1/6",
    "block": "[[[10,\"tr\"],[14,0,\"group-summary-row\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/group-summary-row.hbs",
    "isStrictMode": false
  });

  class GroupSummaryRow extends _component2.default {}

  _exports.default = GroupSummaryRow;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GroupSummaryRow);
});