define("ember-models-table/components/models-table-server-paginated", ["exports", "@glimmer/tracking", "@ember/object", "@ember/utils", "@ember/runloop", "ember-models-table/components/models-table", "ember-models-table/constants/sort-constants"], function (_exports, _tracking, _object, _utils, _runloop, _modelsTable, _sortConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Table-component with pagination, sorting and filtering.
   *
   * It should be used when pagination, filtering and sorting are done on the server-side. Otherwise, [[Core.ModelsTable | ModelsTable]] should be used.
   *
   * This component extends the base models-table component. For the end user, it can be used (nearly) the same:
   *
   * ```html
   * <ModelsTableServerPaginated
   *   @data={{this.data}}
   *   @columns={{this.columns}}
   *   @themeInstance={{this.themeInstance}}
   *   @doQuery={{this.doQuery}}
   *   @itemsCount={{this.itemsCount}}
   *   @pagesCount={{this.pagesCount}}
   * />
   * ```
   *
   * Usage with block context:
   *
   * ```html
   * <ModelsTableServerPaginated
   *   @data={{this.data}}
   *   @columns={{this.columns}}
   *   @themeInstance={{this.themeInstance}}
   *   @doQuery={{this.doQuery}}
   *   @itemsCount={{this.itemsCount}}
   *   @pagesCount={{this.pagesCount}}
   * as |MT|>
   *   <MT.GlobalFilter />
   *   <MT.DataGroupBySelect />
   *   <MT.ColumnsDropdown />
   *   <MT.Table />
   *   <MT.Footer />
   * </ModelsTableServerPaginated>
   * ```
   *
   * Here `doQuery` is an action-handler used when user interacts with table by changing page number, page size, global or column filter, sorting etc.
   *
   * `itemsCount` and `pageCount` show how many rows are in the table's page and how many pages are overall.
   *
   * ModelsTableServerPaginated yields same components, actions and properties as a ModelsTable does. Check its docs for more info.
   *
   * Check own docs for each component to get detailed info.
   *
   * ModelsTableServerPaginated has a lot of options you may configure, but there are six required properties called `data`, `columns`, `themeInstance`, `doQuery`, `itemsCount` and `pagesCount`.
   *
   * A few things to notice:
   *
   * - When using `filterWithSelect` for a column, you must use `predefinedFilterOptions`, because the automatic loading of possible filter values cannot work here.
   * - There is a new optional field `filteredBy` for columns, which works much like `sortedBy`: if set, this field will be used as query parameter, otherwise it will use the `propertyName`.
   * - Sorting will not use multipleColumnSorting, it will only sort by one column.
   * - If you set `sortedBy: false` on a column, sorting will be disabled for this column.
   *
   * There are a couple of things which can be configured to adapt to your API:
   *
   * ```js
   * // The time to wait until new data is actually loaded.
   * // This can be tweaked to avoid making too many server requests.
   * debounceDataLoadTime: 500,
   *
   * // The query parameters to use for server side filtering / querying.
   * filterQueryParameters: {
   *   globalFilter: 'search',
   *   sort: 'sort',
   *   sortDirection: 'sortDirection',
   *   page: 'page',
   *   pageSize: 'pageSize'
   * },
   * ```
   *
   * This default configuration would try to get the total page count from `model.get('meta.pagesCount')` and the total item count from `model.get('meta.itemsCount')`, and would then go on to build the following query:
   *
   * ```js
   * columns: [
   *   {
   *     propertyName: 'name',
   *     filteredBy: 'model_name'
   *   }
   * ]
   *
   * // after searching globally for "searchtexthere"
   * // and in the name column for "filterforname",
   * // and going to page 2,
   * // the following query would be built:
   * ?page=2&pageSize=50&search=searchtexthere&sort=name&sortDirection=ASC&model_name=filterforname
   * ```
   */
  let ModelsTableServerPaginated = (_class = class ModelsTableServerPaginated extends _modelsTable.default {
    /**
     * True if data is currently being loaded from the server.
     * Can be used in the template to e.g. display a loading spinner.
     */

    /**
     * True if last data query promise has been rejected.
     * Can be used in the template to e.g. indicate stale data or to e.g. show error state.
     */

    /**
     * The time to wait until new data is actually loaded.
     * This can be tweaked to avoid making too many server requests.
     *
     * @default 500
     */
    get debounceDataLoadTime() {
      var _this$args$debounceDa;

      return (_this$args$debounceDa = this.args.debounceDataLoadTime) !== null && _this$args$debounceDa !== void 0 ? _this$args$debounceDa : 500;
    }

    /**
     * The query parameters to use for server side filtering / querying.
     */
    get filterQueryParameters() {
      var _this$args$filterQuer;

      return (_this$args$filterQuer = this.args.filterQueryParameters) !== null && _this$args$filterQuer !== void 0 ? _this$args$filterQuer : this._filterQueryParameters;
    }

    get filteredContent() {
      return this.args.data;
    }
    /**
     * For server side filtering, arrangedContent is same as the filtered content
     */


    get arrangedContent() {
      return this.filteredContent;
    }
    /**
     * For server side filtering, visibleContent is same as the filtered content
     */


    get visibleContent() {
      return this.arrangedContent;
    }

    get arrangedContentLength() {
      var _this$args$itemsCount;

      return (_this$args$itemsCount = this.args.itemsCount) !== null && _this$args$itemsCount !== void 0 ? _this$args$itemsCount : 0;
    }

    get pagesCount() {
      var _this$args$pagesCount;

      return (_this$args$pagesCount = this.args.pagesCount) !== null && _this$args$pagesCount !== void 0 ? _this$args$pagesCount : 1;
    }
    /**
     * The index of the last item that is currently being shown.
     */


    get lastIndex() {
      const pageMax = parseInt(`${this.pageSize}`, 10) * this.currentPageNumber;
      return Math.min(pageMax, this.arrangedContentLength);
    }
    /**
     * This function actually loads the data from the server.
     * It takes the store, modelName and query from the passed in data-object and adds page, sorting & filtering to it.
     */


    _loadData() {
      let query = Object.assign({}, this.args.query || {}); // Add pagination information

      query[this.filterQueryParameters.page] = this.currentPageNumber;
      query[this.filterQueryParameters.pageSize] = this.pageSize; // Add sorting information

      if (this.sortProperties && this.sortProperties.length) {
        if (this.multipleColumnsSorting) {
          query = this.multipleColumnsSortingWrapper(query, this.sortProperties);
        } else {
          if (this.sortProperties[0]) {
            const [sortBy, sortDirection] = this.sortProperties[0].split(':');
            query = this.singleColumnSortingWrapper(query, sortBy || '', sortDirection || '');
          }
        }
      } else {
        delete query[this.filterQueryParameters.sort];
        delete query[this.filterQueryParameters.sortDirection];
      } // Add global filter


      const globalFilter = this.filterQueryParameters.globalFilter;

      if (this.filterString) {
        query[globalFilter] = this.filterString;
      } else {
        delete query[globalFilter];
      } // Add per-column filter


      if (this.useFilteringByColumns) {
        this.processedColumns.forEach(column => {
          this.setQueryFilter(query, column, column.filterField, column.filterString);
        });
      }

      this.isLoading = true;
      this.isError = false;
      return this.args.doQuery(query).then(() => {
        this.isLoading = false;
        this.isError = false;
      }).catch(() => {
        this.isLoading = false;
        this.isError = true;
      });
    }
    /**
     * Actually set the filter on a query.
     * This can be overwritten for special case handling.
     * Note that this will mutate the given query object!
     */


    setQueryFilter(query, _, filterTitle, filter) {
      if (!(0, _utils.isBlank)(filter)) {
        if (filterTitle) {
          query[filterTitle] = filter;
        }
      } else {
        if (filterTitle) {
          delete query[filterTitle];
        }
      }
    }
    /**
     * Wrapper for sorting query when single column sorting is used
     */


    singleColumnSortingWrapper(query, sortBy, sortDirection) {
      query[this.filterQueryParameters.sort] = sortBy;
      query[this.filterQueryParameters.sortDirection] = sortDirection;
      return query;
    }
    /**
     * Wrapper for sorting query when multi columns sorting is used
     */


    multipleColumnsSortingWrapper(query, sortProperties) {
      query[this.filterQueryParameters.sort] = sortProperties.map(sortProp => {
        const [prop, direction] = sortProp.split(':');
        const sign = (direction || '').toLowerCase() === 'desc' ? '-' : '';
        return `${sign}${prop}`;
      }).join(',');
      return query;
    }

    _loadDataOnce() {
      (0, _runloop.debounce)(this, this._loadData, this.debounceDataLoadTime);
    }

    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "isLoading", false);

      _defineProperty(this, "isError", false);

      _initializerDefineProperty(this, "_filterQueryParameters", _descriptor, this);

      this.multipleColumnsSorting = false;
    }
    /**
     * @event sort
     */


    sort(column) {
      if ((0, _utils.isNone)(column.sortField)) {
        return;
      }

      const currentSorting = column.sorting || _sortConstants.SortConstants.none;
      const newSorting = this.sortMap[currentSorting];

      if (this.multipleColumnsSorting) {
        this._multiColumnsSorting(column, column.sortField, newSorting);
      } else {
        this._singleColumnSorting(column, column.sortField, newSorting);
      }

      this.userInteractionObserver();

      this._loadDataOnce();
    }
    /**
     * @event changeColumnFilter
     */


    changeColumnFilter(newColumnFilterValue, column) {
      super.changeColumnFilter(newColumnFilterValue, column);

      this._loadDataOnce();
    }
    /**
     * @event changeGlobalFilter
     */


    changeGlobalFilter(newGlobalFilterValue) {
      super.changeGlobalFilter(newGlobalFilterValue);

      this._loadDataOnce();
    }
    /**
     * @event changePageSize
     */


    changePageSize(newPageSize) {
      super.changePageSize(newPageSize);

      this._loadDataOnce();
    }
    /**
     * @event goToPage
     */


    goToPage(pageNumber) {
      super.goToPage(pageNumber);

      this._loadDataOnce();
    }
    /**
     * @event clearFilters
     */


    clearFilters() {
      super.clearFilters();

      this._loadDataOnce();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_filterQueryParameters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        globalFilter: 'search',
        sort: 'sort',
        sortDirection: 'sortDirection',
        page: 'page',
        pageSize: 'pageSize'
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sort", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeColumnFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeColumnFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeGlobalFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeGlobalFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePageSize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePageSize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goToPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class);
  _exports.default = ModelsTableServerPaginated;
});