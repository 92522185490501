define("ember-models-table/constants/sort-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SortConstants = void 0;
  let SortConstants;
  _exports.SortConstants = SortConstants;

  (function (SortConstants) {
    SortConstants["asc"] = "asc";
    SortConstants["desc"] = "desc";
    SortConstants["none"] = "none";
  })(SortConstants || (_exports.SortConstants = SortConstants = {}));
});