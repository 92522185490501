define("ember-models-table/components/models-table/themes/ember-paper/row-sorting-cell", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/row-sorting-cell", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _rowSortingCell, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <th
    class="{{@themeInstance.theadCell}} {{@column.className}}"
    colspan="{{@column.realColspanForSortCell}}">
    {{#if @column.componentForSortCell}}
      {{#let
        (component (ensure-safe-component @column.componentForSortCell)
          column=@column
          selectedItems=@selectedItems
          expandedItems=@expandedItems
          data=@data
          themeInstance=@themeInstance
          expandAllRows=@expandAllRows
          toggleAllSelection=@toggleAllSelection
          collapseAllRows=@collapseAllRows
          sort=this.onClick
        )
      as |CellContent|
      }}
        {{#if (has-block)}}
          {{yield CellContent}}
        {{else}}
          <CellContent/>
        {{/if}}
      {{/let}}
    {{else}}
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        <span role="button" tabindex="0"  {{on "click" this.onClick}}>
          {{@column.columnTitle}}
          {{#if @column.useSorting}}
            {{#if (or @column.sortAsc @column.sortDesc)}}
              <this.PaperIcon
                @icon={{if @column.sortAsc @themeInstance.sortAscIcon (if @column.sortDesc @themeInstance.sortDescIcon)}}
                class={{if @column.sortAsc @themeInstance.sortAscIcon (if @column.sortDesc @themeInstance.sortDescIcon)}}
              />
            {{/if}}
          {{/if}}
        </span>
      {{/if}}
    {{/if}}
  </th>
  */
  {
    "id": "2Dxd89HW",
    "block": "[[[10,\"th\"],[15,0,[29,[[30,1,[\"theadCell\"]],\" \",[30,2,[\"className\"]]]]],[15,\"colspan\",[29,[[30,2,[\"realColspanForSortCell\"]]]]],[12],[1,\"\\n\"],[41,[30,2,[\"componentForSortCell\"]],[[[44,[[50,[28,[37,3],[[30,2,[\"componentForSortCell\"]]],null],0,null,[[\"column\",\"selectedItems\",\"expandedItems\",\"data\",\"themeInstance\",\"expandAllRows\",\"toggleAllSelection\",\"collapseAllRows\",\"sort\"],[[30,2],[30,3],[30,4],[30,5],[30,1],[30,6],[30,7],[30,8],[30,0,[\"onClick\"]]]]]],[[[41,[48,[30,10]],[[[1,\"        \"],[18,10,[[30,9]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,9],null,null,null],[1,\"\\n\"]],[]]]],[9]]]],[]],[[[41,[48,[30,10]],[[[1,\"      \"],[18,10,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,1],[24,\"role\",\"button\"],[24,\"tabindex\",\"0\"],[4,[38,6],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n        \"],[1,[30,2,[\"columnTitle\"]]],[1,\"\\n\"],[41,[30,2,[\"useSorting\"]],[[[41,[28,[37,7],[[30,2,[\"sortAsc\"]],[30,2,[\"sortDesc\"]]],null],[[[1,\"            \"],[8,[30,0,[\"PaperIcon\"]],[[16,0,[52,[30,2,[\"sortAsc\"]],[30,1,[\"sortAscIcon\"]],[52,[30,2,[\"sortDesc\"]],[30,1,[\"sortDescIcon\"]]]]]],[[\"@icon\"],[[52,[30,2,[\"sortAsc\"]],[30,1,[\"sortAscIcon\"]],[52,[30,2,[\"sortDesc\"]],[30,1,[\"sortDescIcon\"]]]]]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]]]],[]]],[13]],[\"@themeInstance\",\"@column\",\"@selectedItems\",\"@expandedItems\",\"@data\",\"@expandAllRows\",\"@toggleAllSelection\",\"@collapseAllRows\",\"CellContent\",\"&default\"],false,[\"if\",\"let\",\"component\",\"ensure-safe-component\",\"has-block\",\"yield\",\"on\",\"or\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/row-sorting-cell.hbs",
    "isStrictMode": false
  });

  class RowSortingCell extends _rowSortingCell.default {
    get PaperIcon() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperIcon)(), this);
    }

  }

  _exports.default = RowSortingCell;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowSortingCell);
});